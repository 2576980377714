import Div from "@jumbo/shared/Div/Div";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { loadUser } from "app/redux/actions/userAction";
import { codeList, genderList } from "app/utils/constants/dropdowns.js";
import { dateFun } from "app/utils/constants/functions";
import axios from "axios";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

export default function EditUserProfile() {
  const { user } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const [data, setData] = useState({
    email_id: user?.email_id ? user?.email_id : "",
    first_name: user?.first_name ? user?.first_name : "",
    last_name: user?.last_name ? user?.last_name : "",
    country_code: user?.country_code ? user?.country_code : "+91",
    mobile_no: user?.mobile_no ? user?.mobile_no : null,
    gender: user?.gender ? user?.gender : "Select",
    age: user?.age ? user?.age : "",
    // date_of_birth: user?.date_of_birth ? user?.date_of_birth : formattedDate,
  });

  useEffect(() => {
    setData({
      email_id: user?.[0]?.email_id ? user?.[0]?.email_id : "",
      first_name: user?.[0]?.first_name ? user?.[0]?.first_name : "",
      last_name: user?.[0]?.last_name ? user?.[0]?.last_name : "",
      country_code: user?.[0]?.country_code ? user?.[0]?.country_code : "+91",
      mobile_no: user?.[0]?.mobile_no ? user?.[0]?.mobile_no : null,
      gender: user?.[0]?.gender ? user?.[0]?.gender : "Select",
      age: user?.[0]?.age ? user?.[0]?.age : "",
      // date_of_birth: user?.date_of_birth ? user?.date_of_birth : formattedDate,
    });
  }, [user]);

  const validationSchema = yup.object({
    first_name: yup
      .string("Enter First Name")
      .required("First Name is required")
      .matches(
        /^[A-Za-z\s]+$/,
        "First Name must contain only alphabetic characters"
      ),
    last_name: yup
      .string("Enter Last Name")
      .required("Last Name is required")
      .matches(
        /^[A-Za-z\s]+$/,
        "Last Name must contain only alphabetic characters"
      ),

    mobile_no: yup
      .number()
      .typeError("mobile_no number must be a number")
      .required("mobile_no Number is Required"),
    gender: yup
      .string()
      .required("Gender is required")
      .test(
        "gender-not-select",
        "Please select a valid Gender",
        (value) => value !== "Select"
      ),
    age: yup
      .number()
      .typeError("Age must be a number")
      .required("Age is required"),
    // date_of_birth: yup
    //   .date()
    //   .test("not-current-date", "Enter Valid Date of Birth", function (value) {
    //     if (!value) {
    //       // Handle case where value is not provided
    //       return false;
    //     }

    //     const currentDate = new Date();
    //     currentDate.setHours(0, 0, 0, 0); // Set time to midnight

    //     return value < currentDate; // Change this to <= to allow the current date
    //   })
    //   .required("Date Of Birth is required"),
  });

  const onUserSave = async (values) => {
    setSubmitting(true);
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.post(
        `${process.env.REACT_APP_URL}/profile/update-user-profile?id=${user?.[0]?._id}`,
        { ...values, date_of_birth: dateFun(values?.date_of_birth) },
        config
      );
      if (data.status === 200) {
        Swal.fire({ icon: "success", title: "Profile Updated" });
        navigate("/user");
        dispatch(loadUser());
      }
    } catch (error) {
      Swal.fire({ icon: "error", title: "Profile Not Updated" });
    }
    setSubmitting(false);
  };

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">Edit User Profile</Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={data}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
          // onSubmit={(values) => console.log(values)}
        >
          {({ setFieldValue, values, errors }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.first_name}
                        helperText={errors.first_name}
                        label="First Name*"
                        name="first_name"
                        value={values.first_name}
                        onChange={(e) =>
                          setFieldValue("first_name", e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.last_name}
                        helperText={errors.last_name}
                        label="Last Name*"
                        name="last_name"
                        value={values.last_name}
                        onChange={(e) =>
                          setFieldValue("last_name", e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.email_id}
                        helperText={errors.email_id}
                        label="Email ID*"
                        name="email_id"
                        value={values.email_id}
                        onChange={(e) =>
                          setFieldValue("email_id", e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <FormControl fullWidth>
                      <TextField
                        label="Gender *"
                        name="gender"
                        value={values.gender}
                        onChange={(e) =>
                          setFieldValue("gender", e.target.value)
                        }
                        select
                        fullWidth
                        error={errors.gender}
                        helperText={errors.gender}
                        InputLabelProps={{
                          shrink: values.gender,
                        }}
                        SelectProps={{
                          native: false,
                        }}
                      >
                        <MenuItem value="Select">Select</MenuItem>
                        {genderList.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <FormControl fullWidth>
                      <TextField
                        error={errors.age}
                        helperText={errors.age}
                        label="Age*"
                        name="age"
                        value={values.age}
                        onChange={(e) => setFieldValue("age", e.target.value)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <FormControl fullWidth>
                      <Grid container spacing={1}>
                        <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                          <FormControl fullWidth>
                            <TextField
                              name="country_code"
                              value={values.country_code}
                              onChange={(e) =>
                                setFieldValue("country_code", e.target.value)
                              }
                              select
                              fullWidth
                              error={errors.country_code}
                              helperText={errors.country_code}
                              InputLabelProps={{
                                shrink: values.country_code,
                              }}
                              SelectProps={{
                                native: false,
                              }}
                            >
                              <MenuItem value="Select">Select</MenuItem>
                              {codeList.map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>

                        <Grid item xs={8} sm={8} md={9} lg={9} xl={9}>
                          <TextField
                            error={errors.mobile_no}
                            helperText={errors.mobile_no}
                            label="Mobile No*"
                            name="mobile_no"
                            value={values.mobile_no}
                            onChange={(e) =>
                              setFieldValue("mobile_no", e.target.value)
                            }
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>

                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/user");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
