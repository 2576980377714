import Page from "@jumbo/shared/Page/Page";
import ChangePassword from "app/pages/ChangePassword";
import AddUser from "app/pages/UserManagement/AddUser";
import ListUser from "app/pages/UserManagement/ListUser";
import UserRouteMiddleware from "./middleware/auth/userValidRoute";

const userManagementRoutes = [
  {
    middleware: [
      {
        element: UserRouteMiddleware,
        fallbackPath: "/user",
      },
    ],
    routes: [
      {
        path: "/user",
        element: <Page component={ListUser} layout={"vertical-default"} />,
      },

      {
        path: "/adduser",
        element: <Page component={AddUser} layout={"vertical-default"} />,
      },
      {
        path: "/edituser",
        element: <Page component={AddUser} layout={"vertical-default"} />,
      },

      {
        path: "/changepassword",
        element: (
          <Page component={ChangePassword} layout={"vertical-default"} />
        ),
      },
    ],
  },
];

export default userManagementRoutes;
