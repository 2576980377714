import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { addRole } from "app/services/apis/addRole";
import { updateRole } from "app/services/apis/updateRole";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function PermissionList() {
  const GreenCheckbox = withStyles({
    root: {
      "&$checked": {
        color: "green",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);
  const { state } = useLocation();

  const headingStyle = {
    minWidth: "20%",
    fontSize: "1rem",
    fontWeight: "bold",
  };
  const checkboxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 4,
  };

  const [isSubmitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [role_name, setRoleName] = useState(
    state?.role_name ? state?.role_name : ""
  );
  const [roleRemarks, setRoleRemarks] = useState(
    state?.roles_remarks ? state?.roles_remarks : ""
  );

  const [status, setStatus] = useState(state?.status === false ? false : true);

  const [check, setCheck] = useState(
    state?.permissions
      ? state?.permissions
      : {
          user_view: false,
          user_edit: false,
          user_create: false,
          role_view: false,
          role_edit: false,
          role_create: false,
          video_view: false,
          video_edit: false,
          video_create: false,
          client_view: false,
          client_edit: false,
          client_create: false,
          blog_view: false,
          blog_edit: false,
          blog_create: false,
          course_view: false,
          course_edit: false,
          course_create: false,
          career_category_view: false,
          career_category_edit: false,
          career_category_create: false,
          career_view: false,
          career_edit: false,
          career_create: false,
          enquiry_view: false,
          enquiry_edit: false,
          enquiry_create: false,
          job_application_view: false,
          job_application_edit: false,
          job_application_create: false,
        }
  );

  const [selectAll, setSelectAll] = useState({
    edit: false,
    view: false,
    create: false,
  });

  const handleChange = (event) => {
    setCheck({ ...check, [event.target.name]: event.target.checked });
  };

  const handleAllView = (e) => {
    const updatedObj = { ...check }; // Create a copy of the original object
    setSelectAll({ ...selectAll, view: e.target.checked });
    // Iterate through the keys of the object
    for (const key in updatedObj) {
      if (key.endsWith("_view")) {
        updatedObj[key] = e.target.checked;
      }
    }
    setCheck(updatedObj);
  };
  const handleAllEdit = (e) => {
    const updatedObj = { ...check }; // Create a copy of the original object
    setSelectAll({ ...selectAll, edit: e.target.checked });

    // Iterate through the keys of the object
    for (const key in updatedObj) {
      if (key.endsWith("_edit")) {
        updatedObj[key] = e.target.checked;
      }
    }
    setCheck(updatedObj);
  };
  const handleAllCreate = (e) => {
    const updatedObj = { ...check }; // Create a copy of the original object
    setSelectAll({ ...selectAll, create: e.target.checked });
    // Iterate through the keys of the object
    for (const key in updatedObj) {
      if (key.endsWith("_create")) {
        updatedObj[key] = e.target.checked;
      }
    }
    setCheck(updatedObj);
  };
  const handleSubmit = async (e) => {
    if (role_name === "") {
      return Swal.fire({ icon: "warning", title: "Fill Role Name", text: "" });
    }
    setSubmitting(true);

    if (pathname === "/addrole") {
      const details = {
        name: role_name,
        permissions: check,
        status: status,
      };
      const data = await addRole(details);

      if (data.status === 201) {
        Swal.fire({
          icon: "success",
          title: data.data.message,
          text: "",
        });
        navigate("/roles");
      } else {
        setSubmitting(false);

        Swal.fire({
          icon: "error",
          title: data.message,
          text: "",
        });
      }
    } else {
      const details = {
        id: state._id,
        name: role_name,
        permissions: check,
        status: status,
      };
      const data = await updateRole(details);

      if (data.status === 200) {
        Swal.fire({
          icon: "success",
          title: data.data.message,
          text: "",
        });
        navigate("/roles");
      } else {
        setSubmitting(false);

        Swal.fire({
          icon: "error",
          title: data.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };

  return (
    <Div sx={{ width: "100%" }}>
      <Grid container rowSpacing={3} columnSpacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <FormControl fullWidth>
            <TextField
              label="Role Name*"
              name="role_name"
              value={role_name}
              onChange={(event) => {
                setRoleName(event.target.value);
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <FormControl fullWidth>
            <TextField
              label="Role Remarks "
              name="role_remarks"
              value={roleRemarks}
              onChange={(event) => {
                setRoleRemarks(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Div
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          mt: 3,
        }}
      >
        <Typography sx={headingStyle}>Select</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox checked={selectAll.view} onChange={handleAllView} />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox checked={selectAll.edit} onChange={handleAllEdit} />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={selectAll.create}
            onChange={handleAllCreate}
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          mt: 3,
        }}
      >
        <Typography sx={headingStyle}>User Management</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.user_view}
            onChange={handleChange}
            name="user_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.user_edit}
            onChange={handleChange}
            name="user_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.user_create}
            onChange={handleChange}
            name="user_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Roles & Permissions</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.role_view}
            onChange={handleChange}
            name="role_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.role_edit}
            onChange={handleChange}
            name="role_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.role_create}
            onChange={handleChange}
            name="role_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Course Management</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.course_view}
            onChange={handleChange}
            name="course_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.course_edit}
            onChange={handleChange}
            name="course_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.course_create}
            onChange={handleChange}
            name="course_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>

      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Career Category</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.career_category_view}
            onChange={handleChange}
            name="career_category_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.career_category_edit}
            onChange={handleChange}
            name="career_category_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.career_category_create}
            onChange={handleChange}
            name="career_category_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Video Management</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.video_view}
            onChange={handleChange}
            name="video_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.video_edit}
            onChange={handleChange}
            name="video_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.video_create}
            onChange={handleChange}
            name="video_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>

      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Client Management</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.client_view}
            onChange={handleChange}
            name="client_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.client_edit}
            onChange={handleChange}
            name="client_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.client_create}
            onChange={handleChange}
            name="client_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Career Management</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.career_view}
            onChange={handleChange}
            name="career_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.career_edit}
            onChange={handleChange}
            name="career_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.career_create}
            onChange={handleChange}
            name="career_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>

      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Blog Management</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.blog_view}
            onChange={handleChange}
            name="blog_view"
          />
          <Typography>View</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.blog_edit}
            onChange={handleChange}
            name="blog_edit"
          />
          <Typography>Edit</Typography>
        </Div>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.blog_create}
            onChange={handleChange}
            name="blog_create"
          />
          <Typography>Create</Typography>
        </Div>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Enquiry</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.enquiry_view}
            onChange={handleChange}
            name="enquiry_view"
          />
          <Typography>View</Typography>
        </Div>
      </Div>
      <Div sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={headingStyle}>Job Application</Typography>
        <Div sx={checkboxStyle}>
          <GreenCheckbox
            checked={check.job_application_view}
            onChange={handleChange}
            name="job_application_view"
          />
          <Typography>View</Typography>
        </Div>
      </Div>

      <Div sx={{ mt: 5 }}>
        <Typography variant="h5">Status</Typography>
        <Switch
          onChange={(e) => {
            setStatus(status === true ? false : true);
          }}
          defaultChecked={status === true ? true : false}
          sx={{
            p: 0,
            width: "70px",
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: status === true ? "green" : "red",
              width: "90%",
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: status === true ? "green" : "red",
            },
          }}
        />
      </Div>
      <Div
        sx={{
          width: "93.5%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          mt: 3,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            Swal.fire({
              title: "Are you sure you want to cancel?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/roles");
              }
            });
          }}
        >
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          type="submit"
          sx={{ width: "100px" }}
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          Save
        </LoadingButton>
      </Div>
    </Div>
  );
}
