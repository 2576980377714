import Page from "@jumbo/shared/Page";
import Login from "app/pages/login";
import MyRouteMiddleware from "./middleware/auth/authValidRoute";
import Dashboard from "app/pages/Dashboard";
import ForgotPassword from "app/pages/ForgotPassword";
import PasswordOtp from "app/pages/Otp";
import EditUserProfile from "app/pages/UserManagement/Edit Profile";
import blogRoutes from "./blogRoutes";
import userManagementRoutes from "./userManagementRoutes";
import roleManagementRoutes from "./roleManagementRoutes";
import clientManagementRoutes from "./clientManagementRoutes";
import { videoRoutes } from "./videoRoutes";
import careertRoutes from "./careerRoutes";
import courseManagementRoutes from "./courseRoutes";
import masterRoutes from "./masterRoutes";

const routesForPublic = [
  {
    path: "/",
    element: <Page component={Login} layout={"solo-page"} />,
  },
  {
    path: "/user/login",
    element: <Page component={Login} layout={"solo-page"} />,
  },
  {
    path: "/password/reset",
    element: <Page component={ForgotPassword} layout={"solo-page"} />,
  },
  {
    path: "/password/otp",
    element: <Page component={PasswordOtp} layout={"solo-page"} />,
  },
  {
    middleware: [
      {
        element: MyRouteMiddleware,
        fallbackPath: "/user/login",
      },
    ],
    routes: [
      {
        path: "/profile",
        element: (
          <Page component={EditUserProfile} layout={"vertical-default"} />
        ),
      },
      {
        path: "/dashboard",
        element: <Page component={Dashboard} layout={"vertical-default"} />,
      },
      ...userManagementRoutes,
      ...roleManagementRoutes,
      ...clientManagementRoutes,
      ...videoRoutes,
      ...blogRoutes,
      ...careertRoutes,
      ...courseManagementRoutes,
      ...masterRoutes,
    ],
  },
];

const routesForAuthenticatedOnly = [];

const routesForNotAuthenticatedOnly = [];

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
];

export {
  routes as default,
  routesForAuthenticatedOnly,
  routesForNotAuthenticatedOnly,
  routesForPublic,
};
